
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, span, a, div, ul, li, h1, h2, h3, h4, div {
  font-family: 'Source Sans Pro', sans-serif;
}

a:visited {
  text-decoration: none;
  color: #000000;
}

.row-max {
  max-width: 90%;
  margin: 0 5%;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.cursor-hover:hover {
  cursor: pointer;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

@-webkit-keyframes wink {
  0% {-webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  }
  100% {-webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);}
} 

/* Standard syntax */ 
@keyframes wink {

  0% {-webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  -o-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
  }

  100% {-webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);}
}

::-webkit-input-placeholder {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
}
::-moz-placeholder {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
}
:-ms-input-placeholder {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
}
:-moz-placeholder {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

button:hover {
  cursor: pointer;
}

.side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: linear-gradient(to bottom,#222e3f,#19222e);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: flex;
  align-items: center;
  transition: all 200ms;
}

.side-drawer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
}

.side-drawer ul li {
  width: 100%;
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  padding: 12px 0;
}

.side-drawer ul li:hover {
  cursor: pointer;
}

.side-drawer ul li a {
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.slide-right-enter {
  transform: translateX(100%);
  transition: all 200ms;
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition: all 200ms;
}

@media (max-width: 1140px) {
  .section h2 {
      font-size: 3rem !important;
  }
}

@media (max-width: 600px) {
  .section h2 {
      font-size: 2.6rem !important;
  }
}

@media (max-width: 501px) {
  .side-drawer ul li {
    font-size: 1.6rem;
  }
}



/*B3CCB9, C8BAAD, DC965A, 1D84B5*/
/*
#CBB7A6
#7D94BS
#C29591
#703F37
#B6C199

#
#
#
#

*/